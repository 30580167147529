<script>
import {getApplicantFullNAme} from "@/helpers/functions";

export default {
  name: "funding-table",
  props: {
    funding: {
      type: Array,
      default(){
        return []
      }
    },
  },
  data(){
    return {
    }
  },
  methods: {
    getApplicantFullNAme,
    openShowFundingRequest(fr){
      this.$emit('openShowFunding', fr)
    },
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Demandeur</th>
        <th scope="col">Téléphone</th>
        <th scope="col">Montant demandé</th>
        <th scope="col">Intéret (%)</th>
        <th scope="col">Montant intéret</th>
        <th scope="col">Montant à rembourser</th>
        <th scope="col">Statut</th>
        <th scope="col">Montant remboursé</th>
        <th scope="col">Date</th>
        <th scope="col">Echéance</th>
<!--        <th scope="col">Action</th>-->
      </tr>
      </thead>
      <tbody>
      <tr v-for="(f, index) in funding" :key="index">
        <th scope="row">{{index + 1}}</th>
        <td><router-link :to="'/admin/members/' + f.fundingUser.uniqId">{{ f.fundingRequest[0]?.applicant[0].firstName }} {{ f.fundingRequest[0]?.applicant[0].firstName }}</router-link></td>
        <td>{{ f?.fundingRequest[0]?.applicant[0].phoneNumber }}</td>
        <td>{{ f.amount?.toLocaleString() }}</td>
        <td>{{ f.rate }}</td>
        <td>{{ f.interestAmount?.toLocaleString() }}</td>
        <td>{{ f.refundAmount?.toLocaleString() }}</td>
        <td>
          <BBadge v-if="f.status === 0 || !f.status" variant="dark">Créée</BBadge>
          <BBadge v-else-if="f.status === 1" variant="warning">En Cours</BBadge>
          <BBadge v-else-if="f.status === 2" variant="success">Remboursé</BBadge>
        </td>
        <td>{{ f?.refund.refundedAmount.toLocaleString() }}</td>
        <td>{{ f.startAt.split('T')[0] }}</td>
        <td>{{ f.deathLine }}</td>
<!--        <td>-->
<!--          <div class="hstack gap-3 fs-15">-->
<!--            <router-link :to="'/admin/funding/' + f.uniqId" class="link-primary">-->
<!--              <i class="ri-eye-fill"></i>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </td>-->
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>